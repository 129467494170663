import { useParams, Link, useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import './card.css';
import './Apartmentslide.css';
import './apartmentDetails.css'

function Apartments() {
    const { buildingId } = useParams(); // Get buildingId from the URL
    const [apartments, setApartments] = useState([]);
    const [building, setBuilding] = useState(null); // To store building info, including image
    const [gender, setGender] = useState("");
    const [apartmentType, setApartmentType] = useState("");
    const [maxPrice, setMaxPrice] = useState("");
    const navigate = useNavigate();
    const sliderRef = useRef(null); // Ref to track the slider div

    useEffect(() => {
        fetchBuildingInfo();
        fetchApartments();
        window.scrollTo(0, 190);
    }, [buildingId, gender, apartmentType, maxPrice]);

    const fetchBuildingInfo = () => {
        // Fetch the building information using the buildingId
        fetch(`https://backendjaes.passiontrials.co.za/building_image/${buildingId}`)
            .then((response) => response.json())
            .then((data) => {
                setBuilding(data); // Set building info (including the image)

            })

            .catch((error) => console.error('Failed to load building info:', error));
    };

    const fetchApartments = () => {
        let url = `https://backendjaes.passiontrials.co.za/apartment_tb/${buildingId}?`;
        if (gender) url += `gender=${gender}&`;
        if (apartmentType) url += `apartmentType=${apartmentType}&`;
        if (maxPrice) url += `maxPrice=${maxPrice}&`;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                setApartments(data.data);
            })
            .catch(error => console.error('Failed to load apartments:', error));
    };

   

    const handleNextPage = (id) => {
        navigate(`/ApartmentDetails/${id}`);
    };
    const handleNext = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollLeft += sliderRef.current.offsetWidth;
        }
    };

    const handlePrev = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollLeft -= sliderRef.current.offsetWidth;
        }
    };
    return (
        <div style={{  backgroundColor: 'white' }}>
            {/* Breadcrumb Section */}
            <div className="breadcrumb-container">
                <div className="header-container">
                    <div className="header-text">{building?.BuildingName}</div>
                    <div className="subheader-text">{building?.BuildingLocation}</div>
                </div>
                <div className="breadcrumb">
                    <Link to="/"> Home </Link> <span> / </span>
                    <Link to="/Buildings"> Properties </Link> <span> / </span>
                    {building?.BuildingName}
                </div>
            </div>

            {/* Display the Building Image */}
            {/* <div className="building-images-container">
                {building && (
                    <div className="main-image-container">
                    
                        <img
                            src={`https://backendjaes.passiontrials.co.za/src${building.BuildingExteriorImage4}`}
                            alt={building.BuildingName}
                            className="big-building-image"
                        />
                    </div>
                )}

               
                <div className="small-images-container">
                    {building && (
                        <>
                            <img
                                src={`https://backendjaes.passiontrials.co.za/src${building.BuildingExteriorImage4}`}
                                alt={`${building.BuildingName} view 1`}
                                className="small-building-image"
                            />
                            <img
                                src={`https://backendjaes.passiontrials.co.za/src/Building_images/Bartsplace_shower.jpeg`}
                                alt={`${building.BuildingName} view 2`}
                                className="small-building-image"
                            />
                        </>
                    )}
                </div>
            </div> */}
            <div class="ferferfds544">
     
     <input type="radio" name="carousel-ferferfds544" id="image1-ferferfds544" checked/>
     <input type="radio" name="carousel-ferferfds544" id="image2-ferferfds544"/>
     <input type="radio" name="carousel-ferferfds544" id="image3-ferferfds544"/>
     <input type="radio" name="carousel-ferferfds544" id="image4-ferferfds544"/>
     <input type="radio" name="carousel-ferferfds544" id="image5-ferferfds544"/>

     <div className="carousel-ferferfds544">
     {building?.BuildingInteriorImage1 && (
        <img 
            src={`https://backendjaes.passiontrials.co.za/src${building.BuildingInteriorImage1}`} 
            alt="Main Image 1" 
        />
    )}
    {building?.BuildingInteriorImage2 && (
        <img 
            src={`https://backendjaes.passiontrials.co.za/src${building.BuildingInteriorImage2}`} 
            alt="Main Image 1" 
        />
    )}
    {building?.BuildingInteriorImage3 && (
        <img 
            src={`https://backendjaes.passiontrials.co.za/src${building.BuildingInteriorImage3}`} 
            alt="Main Image 2" 
        />
    )}
    {building?.BuildingExteriorImage4 && (
        <img 
            src={`https://backendjaes.passiontrials.co.za/src${building.BuildingExteriorImage4}`} 
            alt="Main Image 3" 
        />
    )}
    {building?.BuildingExteriorImage5 && (
        <img 
            src={`https://backendjaes.passiontrials.co.za/src${building.BuildingExteriorImage5}`} 
            alt="Main Image 4" 
        />
    )}
</div>

<div className="image-gallery-ferferfds544">
    {building?.BuildingInteriorImage1 && (
        <label htmlFor="image1-ferferfds544">
            <img 
                src={`https://backendjaes.passiontrials.co.za/src${building.BuildingInteriorImage1}`} 
                alt="Image 1" 
            />
        </label>
    )}
    {building?.BuildingInteriorImage2 && (
        <label htmlFor="image2-ferferfds544">
            <img 
                src={`https://backendjaes.passiontrials.co.za/src${building.BuildingInteriorImage2}`} 
                alt="Image 2" 
            />
        </label>
    )}
    {building?.BuildingInteriorImage3 && (
        <label htmlFor="image3-ferferfds544">
            <img 
                src={`https://backendjaes.passiontrials.co.za/src${building.BuildingInteriorImage3}`} 
                alt="Image 3" 
            />
        </label>
    )}
    {building?.BuildingExteriorImage4 && (
        <label htmlFor="image4-ferferfds544">
            <img 
                src={`https://backendjaes.passiontrials.co.za/src${building.BuildingExteriorImage4}`} 
                alt="Image 4" 
            />
        </label>
    )}
    {building?.BuildingExteriorImage5 && (
        <label htmlFor="image5-ferferfds544">
            <img 
                src={`https://backendjaes.passiontrials.co.za/src${building.BuildingExteriorImage5}`} 
                alt="Image 5" 
            />
        </label>
    )}
</div>


 </div>


            {/* Filter Section */}
            {/* Filter Section */}
<div
  style={{
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '30px',
    border: '1px solid grey', /* Add a black line (border) */
    borderRadius: '20px',
    padding: '10px',
    width: '80%', /* Set a lower width */
    maxWidth: '900px', /* Set a max width to limit it */
    margin: '0 auto',
    marginTop: '20px', /* Center the div horizontally */
    flexWrap: 'wrap', /* Allow wrapping */
  }}
>
  <div className="filter-section" style={{ display: 'flex', alignItems: 'center', gap: '10px', flexWrap: 'wrap' }}>
    <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
      <div className="filter-item">
        {/* <label>Gender:</label> */}
        <select value={gender} onChange={(e) => setGender(e.target.value)}>
          <option value="">All Genders</option>
          <option value="0">Female</option>
          <option value="1">Male</option>
          <option value="2">Male | Female</option>
        </select>
      </div>

      <div className="filter-item">
        {/* <label>Apartment Type:</label> */}
        <select value={apartmentType} onChange={(e) => setApartmentType(e.target.value)}>
          <option value="">All Types</option>
          <option value="One Bedroom">One Bedroom</option>
          <option value="Single with Ensuite">Single with Ensuite</option>
          <option value="Sharing Double">Sharing Double</option>
          <option value="Sharing Double with Ensuite">Sharing Double with Ensuite</option>
          <option value="Sharing Triple">Sharing Triple</option>
          <option value="Bachelor Pods">Bachelor Pods</option>
        </select>
      </div>
    </div>

    <div style={{ display: 'flex', gap: '10px' }}>
      <div className="filter-item">
        {/* <label>Max Price:</label> */}
        <input
          type="number"
          value={maxPrice}
          onChange={(e) => setMaxPrice(e.target.value)}
          placeholder="Enter max price"
          style={{
            height: '48px', /* Increased height */
            borderRadius: '9px', /* Rounded corners */
            padding: '0 10px', /* Adding padding for better spacing */
          }}
        />
      </div>

      <button
        onClick={fetchApartments}
        style={{
            display:'none',
          height: '48px', /* Increased height */
          borderRadius: '39px', /* Rounded corners */
          backgroundColor: '#ff5a5f', /* New background color */
          color: 'white', /* Text color */
          border: 'none', /* Remove border */
          padding: '0 20px', /* Adjust padding */
          cursor: 'pointer',
        }}
      >
        Apply Filters
      </button>
    </div>
  </div>
</div>

{/* Mobile Styles */}
<style>
{`
@media (max-width: 768px) {
  .filter-section {
    flex-direction: column;
  }
  
  /* Group Gender and Type together */
  .filter-item:first-child, .filter-item:nth-child(2) {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  /* Group Price and Button together */
  .filter-item:nth-child(3), button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}
`}
</style>

            <div className="Apartdsadsdas" id="Apartdsadsdas" ref={sliderRef}>
            {apartments.length > 0 ? (
                    apartments
                        .filter(property => property.Capacity2 !== null)  // Filter out apartments with Capacity2 == 0
                        .map((property, index) => (
                            <div className="room-card" key={index}>
                                <img src={`https://backendjaes.passiontrials.co.za/src${property.ApartmentRoomImage}`} alt="Room" className="room-card-image" />
                                <div className="room-card-content" onClick={() => handleNextPage(property.ApartmentId)}>
                                    <h2 className="room-title">{property.ApartmentNumber}</h2>
                                    <p className="room-type">{property.LivingArrangement}</p>
                                    <div className="plan-options">
                                        <button className="plan-option">PLAN A | R {property.ApartmentPrice}</button>
                                        <button className="plan-option" style={{ marginLeft: '10px' }}>PLAN B | R {property.PriceB}</button>
                                        {/* <button className="plan-option" style={{ marginLeft: '10px' }}>i</button> */}
                                    </div>
                                    <a href="" className="view-link">Gender: {property.ApartmentPreferedGender}</a>
                                    <a href="" className="view-link">{property.Capacity2} Beds Available</a>
                                    {/* <div className="room-details">
                                        <div className="detail-item">
                                            <p>Gender</p>
                                            <span>{handleGender(property.ApartmentPreferedGender)}</span>
                                        </div>
                                        <div className="detail-item">
                                            <p>Capacity</p>
                                            <span>{property.TotalNumOfBeds}</span>
                                        </div>
                                        <div className="detail-item">
                                            <p>Available Beds</p>
                                            <span>{property.Capacity2}</span>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        ))
                ) : (
                    <p>Loading properties...</p>
                )}
            </div>
 {/* Prev and Next buttons */}
 <div className="fdsdsfdsdsv">
                <button className="dsdss" onClick={handlePrev}>&#10094; Prev</button>
                <button className="dsdss" onClick={handleNext}>Next &#10095;</button>
            </div>
            {/* Apartments Listing */}
            {/* <div className="building-grid">
                {apartments.length > 0 ? (
                    apartments
                        .filter(property => property.Capacity2 !== 0)  // Filter out apartments with Capacity2 == 0
                        .map((property, index) => (
                            <div className="room-card" key={index}>
                                <img src={`https://backendjaes.passiontrials.co.za/src${property.ApartmentRoomImage}`} alt="Room" className="room-card-image" />
                                <div className="room-card-content" onClick={() => handleNextPage(property.ApartmentId)}>
                                    <h2 className="room-title">{property.ApartmentNumber}</h2>
                                    <p className="room-type">{property.LivingArrangement}</p>
                                    <div className="plan-options">
                                        <button className="plan-option">PLAN A | R {property.ApartmentPrice}</button>
                                        <button className="plan-option" style={{ marginLeft: '10px' }}>PLAN B | R {property.PriceB}</button>
                                        <button className="plan-option" style={{ marginLeft: '10px' }}>i</button>
                                    </div>
                                    <a href="" className="view-link">Gender {handleGender(property.ApartmentPreferedGender)}</a>
                                    <a href="" className="view-link">{property.Capacity2} Beds Available</a>
                                    {/* <div className="room-details">
                                        <div className="detail-item">
                                            <p>Gender</p>
                                            <span>{handleGender(property.ApartmentPreferedGender)}</span>
                                        </div>
                                        <div className="detail-item">
                                            <p>Capacity</p>
                                            <span>{property.TotalNumOfBeds}</span>
                                        </div>
                                        <div className="detail-item">
                                            <p>Available Beds</p>
                                            <span>{property.Capacity2}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                ) : (
                    <p>Loading properties...</p>
                )}
            </div> */}
        </div>
    );
}

export default Apartments;
