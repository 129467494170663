import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './Register.css';

function Register() {
    const navigation = useNavigate();
    const [formData, setFormData] = useState({
        Name: '',
        Surname: '',
        IdNumber: '',
        PassportNumber: '',
        Country: '',
        Gender: '',
        DateOfBirth: '',
        Email: '',
        PhoneNumber: '',
        Password: '',
        ConfirmPassword: '',
        TypeOfFunding: '' // Add TypeOfFunding to the form state
    });
    const [useID, setUseID] = useState(true); // Toggle between ID or Passport
    const [step, setStep] = useState(1); // Step 1: Basic info, Step 2: Additional info
    const [loading, setLoading] = useState(false); // Loading state for form submission
    const [faceImage, setFaceImage] = useState(null);
    const [idImage, setIdImage] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'IdNumber' && value.length >= 10) {
            const gender = handleGender(value);
            const dateOfBirth = handleDateOfBirth(value);

            setFormData({
                ...formData,
                [name]: value,
                Gender: gender,
                DateOfBirth: dateOfBirth
            });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleImageChange = (e) => {
        if (e.target.name === 'FaceImage') {
            setFaceImage(e.target.files[0]);
        } else if (e.target.name === 'ID_Image') {
            setIdImage(e.target.files[0]);
        }
    };

    const handleGender = (id) => {
        const genderPart = id.slice(6, 10); // Extract part of the ID number
        const gender = parseInt(genderPart, 10);

        if (gender >= 0 && gender <= 4999) {
            console.log('Gender :', gender, 'this is a Female');
            return 'Female';
        } else if (gender >= 5000 && gender <= 9999) {
            console.log('Gender :', gender, 'this is a Male');
            return 'Male';
        } else {
            return '';
        }
    };

    const handleDateOfBirth = (id) => {
        const datePart = id.slice(0, 6); // Extract part of the ID number
        const year = datePart.slice(0, 2);
        const month = datePart.slice(2, 4);
        const day = datePart.slice(4, 6);

        const fullYear = year.startsWith('0') || year.startsWith('1') ? `20${year}` : `19${year}`;
        const dateOfBirth = `${fullYear}-${month}-${day}`;
        let me = new Date(dateOfBirth).toLocaleDateString('en-US', { day: "numeric", month: 'long', year: 'numeric' });

        console.log('Date of Birth :', me);
        return me;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true while processing the request

        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;

        // Check if the passwords match
        if (formData.Password !== formData.ConfirmPassword) {
            alert("Passwords do not match!");
            setLoading(false); // Stop loading
            return;
        }
    
        // Check if the password meets the strength requirements
        if (!passwordRegex.test(formData.Password)) {
            alert("Password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, one number, and one special character.");
            setLoading(false); // Stop loading
            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append('Name', formData.Name);
        formDataToSend.append('Surname', formData.Surname);
        formDataToSend.append('Email', formData.Email);
        formDataToSend.append('PhoneNumber', formData.PhoneNumber);
        formDataToSend.append('Password', formData.Password);
        formDataToSend.append('TypeOfFunding', formData.TypeOfFunding); // Add TypeOfFunding to the form data

        if (useID) {
            formDataToSend.append('IdNumber', formData.IdNumber);
            formDataToSend.append('Gender', formData.Gender);
            formDataToSend.append('DateOfBirth', formData.DateOfBirth);
        } else {
            formDataToSend.append('PassportNumber', formData.PassportNumber);
            formDataToSend.append('Country', formData.Country);
            formDataToSend.append('Gender', formData.Gender);
            formDataToSend.append('DateOfBirth', formData.DateOfBirth);
        }

        if (faceImage) {
            formDataToSend.append('FaceImage', faceImage);
        }

        if (idImage) {
            formDataToSend.append('ID_Image', idImage);
        }

        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/new_user_register', {
                method: 'POST',
                body: formDataToSend
            });
            const data = await response.json();
            alert(data.message);
            navigation("/login");

        } catch (error) {
            console.error('Error during registration:', error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <div className="register-page">
            <div className="register-container">
                <form onSubmit={handleSubmit} className="register-form">
                    <h2>Create Account</h2>

                    {step === 1 && (
                        <>
                            <div className="form-group">
                                <input name="Name" value={formData.Name} onChange={handleChange} placeholder="Name" required />
                                <input name="Surname" value={formData.Surname} onChange={handleChange} placeholder="Surname" required />
                            </div>
                            <div className="form-group">
                                <input name="Email" value={formData.Email} onChange={handleChange} placeholder="Email" required type="email" />
                                <input name="PhoneNumber" value={formData.PhoneNumber} onChange={handleChange} placeholder="Phone Number" required />
                            </div>
                            <button type="button" className="continue-button" onClick={() => setStep(2)}>Continue</button>
                        </>
                    )}

                    {step === 2 && (
                        <>
                            <div className="form-group">
                                {/* <label>Use ID Number or Passport?</label> */}
                                <select onChange={(e) => setUseID(e.target.value === 'ID')} value={useID ? 'ID' : 'Passport'}>
                                    <option value="ID">ID Number</option>
                                    <option value="Passport">Passport</option>
                                </select>
                            </div>

                            {useID ? (
                                <>
                                    <div className="form-group">
                                        <input name="IdNumber" type='text' maxLength="13" value={formData.IdNumber} onChange={handleChange} placeholder="ID Number" required />
                                    </div>
                                    <div className="form-group">
                                        <input name="Gender" value={formData.Gender} onChange={handleChange} placeholder="Gender" readOnly />
                                        <input name="DateOfBirth" value={formData.DateOfBirth} onChange={handleChange} placeholder="Date of Birth" readOnly />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="form-group">
                                        <input name="PassportNumber" type="text" value={formData.PassportNumber} onChange={handleChange} placeholder="Passport Number" required />
                                        <input name="Country" type="text" value={formData.Country} onChange={handleChange} placeholder="Country" required />
                                    </div>
                                    <div className="form-group gender-group">
                                        <label>Gender:</label>
                                        <label><input type="radio" name="Gender" value="Male" onChange={handleChange} checked={formData.Gender === 'Male'} /> Male</label>
                                        <label><input type="radio" name="Gender" value="Female" onChange={handleChange} checked={formData.Gender === 'Female'} /> Female</label>
                                    </div>
                                    <div className="form-group">
                                        <input name="DateOfBirth" type="date" value={formData.DateOfBirth} onChange={handleChange} placeholder="Date of Birth" required />
                                    </div>
                                </>
                            )}

                            {/* Dropdown for TypeOfFunding */}
                            <div className="form-group">
                                {/* <label>Type of Funding:</label> */}
                                <select name="TypeOfFunding" value={formData.TypeOfFunding} onChange={handleChange} required>
                                    <option value="">Select Funding Type</option>
                                    <option value="self funded">Self Funded</option>
                                    <option value="Bursary">Bursary</option>
                                    <option value="NSFAS">NSFAS</option>
                                </select>
                            </div>

                            {/* <div className="form-group">
                                <label htmlFor="FaceImage">Upload selfie</label>
                                <input name="FaceImage" type="file" onChange={handleImageChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="ID_Image">Upload ID or Passport Image</label>
                                <input name="ID_Image" type="file" onChange={handleImageChange} />
                            </div> */}
                            <div className="form-group password-group">
                                <input name="Password" value={formData.Password} onChange={handleChange} placeholder="Password" required type="password" />
                                <input name="ConfirmPassword" value={formData.ConfirmPassword} onChange={handleChange} placeholder="Confirm Password" required type="password" />
                            </div>
                            <div className="form-navigation">
                                <button type="button" className="back-button" onClick={() => setStep(1)}>Back</button>
                                <button type="submit" className="register-button" disabled={loading}>
                                    {loading ? "Processing..." : "Register"}
                                </button>
                            </div>
                        </>
                    )}

                    {step === 1 && (
                        <p>Already have an account? <Link to="/login" className="login-link">Login</Link></p>
                    )}
                </form>
            </div>
        </div>
    );
}

export default Register;
