import React, { useRef, useState, useEffect } from 'react';
import { Route, Routes, Link, Outlet, useNavigate } from 'react-router-dom';
import MyApartment from './myApartment';
import { useAuth } from './AuthProvider';
import Countdown from "react-countdown";
import './modal.css';
import './userdashboard.css';
import './roundloader.css';

function useOutsideAlerter(ref, onOutsideClick) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onOutsideClick();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, onOutsideClick]);
}
const PaymentModal = ({ isVisible, onClose, onSubmit }) => {
    const [customAmount, setCustomAmount] = useState('');
    const [selectedAmount, setSelectedAmount] = useState(null);
    const [isCustom, setIsCustom] = useState(false);

    const handleAmountChange = (e) => {
        setCustomAmount(e.target.value);
        // setSelectedAmount('');  // Clear predefined amounts if custom is entered
    };



    const handleSubmit = () => {
        const amount = isCustom ? customAmount : selectedAmount;
        if (!amount) {
            alert('Please select or enter an amount.');
            return;
        }
        onSubmit(amount); // Pass the amount to the parent component
    };

    const handleAmountSelect = (amount) => {
        setSelectedAmount(amount);
        setIsCustom(false); // Deselect custom option if a preset amount is chosen
    };

    const handleCustomSelect = () => {
        setIsCustom(true); // Show custom input when "Custom" is selected
        setSelectedAmount(null); // Clear preset selection
    };

    if (!isVisible) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <h2>Select or Enter Payment Amount</h2>
                <div className="amount-options">
                    <button onClick={() => handleAmountSelect(500)}>R500</button>
                    <button onClick={() => handleAmountSelect(1000)}>R1000</button>
                    <button onClick={() => handleAmountSelect(1500)}>R1500</button>
                    <button onClick={() => handleAmountSelect(2000)}>R2000</button>
                    <button onClick={handleCustomSelect}>Custom</button>
                </div>
                {isCustom && (
                    <div className="custom-amount">
                        <input
                            type="number"
                            placeholder="Enter amount"
                            value={customAmount}
                            onChange={handleAmountChange}
                        />
                    </div>
                )}
                <button onClick={handleSubmit} className="submit-btn">Submit</button>
            </div>
        </div>
    );
};
const Loader = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh' // This ensures it takes the full viewport height
        }}>
         
<div class="blobs">
	<div class="blob-center"></div>
	<div class="blob"></div>
	<div class="blob"></div>
	<div class="blob"></div>
	<div class="blob"></div>
	<div class="blob"></div>
	<div class="blob"></div>
</div>
<svg xmlns="http://www.w3.org/2000/svg" version="1.1">
  <defs>
    <filter id="goo">
      <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur"></feGaussianBlur>
      <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo"></feColorMatrix>
      <feBlend in="SourceGraphic" in2="goo"></feBlend>
  	</filter>
  </defs>
</svg>
        </div>
    );
};
const CheckoutModal = ({ isVisible, onClose, onConfirm }) => {
    if (!isVisible) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Confirm Checkout</h2>
                <p>Are you sure you want to checkout?</p>
                <div className="modal-buttons">
                    <button onClick={onConfirm} className="confirm-btn">Confirm</button>
                    <button onClick={onClose} className="cancel-btn">Cancel</button>
                </div>
            </div>
        </div>
    );
};
// Modal for uploading missing images
const ImageUploadModal = ({ isVisible, onClose, onUpload }) => {
    const [faceImage, setFaceImage] = useState(null);
    const [idImage, setIdImage] = useState(null);

    const handleImageChange = (e) => {
        if (e.target.name === 'FaceImage') {
            setFaceImage(e.target.files[0]);
        } else if (e.target.name === 'ID_Image') {
            setIdImage(e.target.files[0]);
        }
    };

    const handleSubmit = () => {
        if (!faceImage || !idImage) {
            alert('Please upload both Face and ID Images.');
            return;
        }

        onUpload(faceImage, idImage); // Pass images to the parent component
        onClose(); // Close the modal after submission
    };

    if (!isVisible) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Upload Missing Images</h2>
                <div className="form-group">
                    <label htmlFor="FaceImage">Upload selfie</label>
                    <input name="FaceImage" type="file" onChange={handleImageChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="ID_Image">Upload ID Image</label>
                    <input name="ID_Image" type="file" onChange={handleImageChange} />
                </div>
                <button onClick={handleSubmit} className="submit-btn">Upload</button>
                <button onClick={onClose} className="cancel-btn">Cancel</button>
            </div>
        </div>
    );
};

function UserDashboard() {
    const [bookings, setBookings] = useState([]);
    const [payments, setPayments] = useState([]);
    const [profile, setProfile] = useState({});
    const [showDropdown, setShowDropdown] = useState(false);
    const [fetchid, setFetchId] = useState(null);
    const { userId } = useAuth();
    const [error, setError] = useState('');
    const [apartment, setApartment] = useState(null);
    const [creditAmount, setCreditAmount] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showimageModal, setShowimageModal] = useState(false);
    const [enteredAmount, setEnteredAmount] = useState(null);
    const navigate = useNavigate();
    const [latestInfo, setLatestInfo] = useState(null);
    const [latestPaymentTrack, setLatestPaymentTrack] = useState(null);
    const [showCheckoutModal, setShowCheckoutModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const [walletData, setWalletData] = useState(null); // Added state for wallet data
    const [bookedTransports, setBookedTransports] = useState([]); // Define the state for booked transports

    useEffect(() => {
        if (apartment) {
            // Log the values to see what's in FaceImage and ID_Image
            console.log('Checking apartment images:', apartment.FaceImage, apartment.ID_Image);
    
            // Check for both null and empty string values
            const faceImageExists = apartment.FaceImage !== null && apartment.FaceImage.trim() !== '';
            const idImageExists = apartment.ID_Image !== null && apartment.ID_Image.trim() !== '';
    
            // Show modal only if either image is missing (null or empty string)
            if (!faceImageExists || !idImageExists) {
                setShowimageModal(true);  // Open modal if images are missing (null or empty string)
                console.log('Modal opened because FaceImage or ID_Image is missing');
            } else {
                setShowimageModal(false); // Close modal if both images are present
                console.log('Modal closed because both images are present');
            }
        }
    }, [apartment]);
    
    
useEffect(() => {
    // Fetch user data and check if images are missing
    const fetchUserDetails = async () => {
        try {
            const response = await fetch(`https://backendjaes.passiontrials.co.za/user_full_details/${userId}`);
            const data = await response.json();
            
            if (data && data.length > 0) {
                const userData = data[0];
                setApartment(userData); // Set apartment data in state
                
                // Check if either FaceImage or ID_Image is missing
                const faceImageExists = userData.FaceImage && userData.FaceImage.trim() !== '';
                const idImageExists = userData.ID_Image && userData.ID_Image.trim() !== '';

                // If any image is missing, show the modal
                if (!faceImageExists || !idImageExists) {
                    setShowimageModal(true); // Open modal if images are missing
                } else {
                    setShowimageModal(false); // Close modal if both images are present
                }
            } else {
                console.log('No user data found');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            setError('Failed to load user data');
        }
    };

    fetchUserDetails();
}, [userId]); // The effect depends on userId
   const handleImageUpload = (faceImage, idImage) => {
    // Create a FormData object to store the images
    const formData = new FormData();
    formData.append('FaceImage', faceImage);
    formData.append('ID_Image', idImage);

    // Make a POST request to the new update endpoint
    fetch(`https://backendjaes.passiontrials.co.za/update_user_images/${apartment.UserId}`, {
        method: 'POST',
        body: formData
    })
    .then(response => response.json())
    .then(data => {
        if (data.message === 'User images updated successfully') {
            alert('Images uploaded successfully!');
            
            // Update the apartment state with new image paths
            setApartment(prevState => ({
                ...prevState,
                FaceImage: data.FaceImagePath,
                ID_Image: data.ID_ImagePath
            }));
            
            setShowModal(false);  // Close the modal after successful upload
        } else {
            alert('Failed to upload images. Please try again.');
        }
    })
    .catch(error => {
        console.error('Error uploading images:', error);
        alert('Failed to upload images. Please try again.');
    });
};

    useEffect(() => {
        fetch(`https://backendjaes.passiontrials.co.za/api/transport/${userId}`)
          .then(response => response.json())
          .then(data => setBookedTransports(data))  // Set the fetched data here
          .catch(error => console.error('Error fetching transport data:', error));
      }, [userId]);
      const formatDate = (dateString) => {
        const options = { weekday: 'short', month: 'short', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);  // Return the formatted date
      };
      
    let theFirst = null;
    let showButtons = null;
    let testCount = null;
    let informedMessage = null;
    let tc = 0;
    let systemInitiated = null;
    const systemProcessCallRef = useRef(false);
    let stopper = 0;

    const wrapperRef = useRef(null);

    ///// this controls the buttons that are shown
    theFirst = latestInfo?.PayDate;

    // theFirst = new Date(theFirst).toLocaleDateString('en-US', { day: 'numeric' });
    //  showButtons = theFirst >= 15 && theFirst <= 31;
    /////

    useOutsideAlerter(wrapperRef, () => setShowDropdown(false));

    useEffect(() => {
        



        
        fetch(`https://backendjaes.passiontrials.co.za/apartment-check/${userId}`, {
            headers: { "Content-Type": "application/json" }
        })
            .then(response => {
                if (!response.ok) { throw new Error("Failed to get a match with user"); }
                return response.json();
            }).
            then(data => {
                if (data) {
                    setFetchId(data);
                    console.log('this is the id for the room: ', data);
                }
                else {
                    console.log('No apartment associated with this user');
                }

            }).
            catch(err => {
                console.error('Error:', err);
                setError(err.message);
            });
    }, []);

    // const nextPaymentDate = new Date(new Date().setDate(new Date().getDate() + 30)); // Assuming 30 days till next payment
    // const currentDate = new Date();
    // const daysLeft = Math.ceil((nextPaymentDate - currentDate) / (1000 * 60 * 60 * 24));
    //eeei
    useEffect(() => {

        //  fetchApartmentDetails();
        if (!fetchid) return;
        fetch(`https://backendjaes.passiontrials.co.za/user_full_details/${userId}`, {
            headers: { "Content-Type": 'application/json' }
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    console.log(data[0]);
                    setApartment(data[0]);
                }
                else {
                    console.log('No apartement data found here');

                }
            })
            .catch(error => {
                console.error('Error', error);
                setError('Failed to load user data');
            });

        fetch(`https://backendjaes.passiontrials.co.za/latest_record/${userId}/${fetchid}`, {
            headers: { 'Content-Type': 'application/json' }
        }).then(response => response.json())
            .then(data => {
                if (data) {
                    console.log("latest record", data);
                    setLatestInfo(data);
                }
                else {
                    console.log('No valid payment record found');
                }
            }).catch(error => {
                console.error('Error', error);
                setError('Failed to load latest record');
            });

        fetch(`https://backendjaes.passiontrials.co.za/latest_payment_tracking/${userId}`, {
            headers: { 'Content-Type': 'application/json' }
        }).then(response => response.json())
            .then(data => {
                if (data) {
                    console.log("latest payment track record", data);
                    setLatestPaymentTrack(data);
                }
                else {
                    console.log('No valid payment tracking record found');
                }
            }).catch(error => {
                console.error('Error with tracking', error);
                setError('Failed to load latest pay tracking record');
            });

            fetch(`https://backendjaes.passiontrials.co.za/wallet/${userId}`, {
                headers: { 'Content-Type': 'application/json' }
            })
                .then(response => response.json())
                .then(data => {
                    if (data && data.length > 0) {
                        console.log('Wallet data:', data);
                        setWalletData(data[0]); // Assuming we want the latest wallet record
                    } else {
                        console.log('No wallet data found');
                    }
                })
                .catch(error => {
                    console.error('Error fetching wallet data:', error);
                    setError('Failed to load wallet data');
                });


    }, [userId, fetchid]);

    // function fetchApartmentDetails() {
    //     const apartmentDetails = {
    //         name: "Luxury Beach House",
    //         imageUrl: "https://picsum.photos/200/300?random=30"
    //     };

    //     setApartment(apartmentDetails);
    // }
    const nextPayDate = latestInfo?.PayNextDate;
    console.log(`non converted :${nextPayDate}`);
    const nextPayDay = new Date(nextPayDate).toLocaleDateString('en-US', { day: "numeric" });
    const converted = nextPayDate ? new Date(nextPayDate) : null;
    console.log(`converted :${converted}`);

    //    nextPaymentDate.setDate(new Date(nextPaymentDate.getFullYear(), nextPaymentDate.getMonth() + 1, 0).getDate());

    //converted.setDate(new Date(converted.getFullYear(), converted.getMonth() + 1, 0).getDate());
    const dateNow = new Date();
    const daysOfStay = converted ? Math.ceil((converted - dateNow) / (1000 * 60 * 60 * 24)) : null;
    console.log('this is the converted date', converted, "this is the current date", dateNow);


    // if (!apartment) {
    //     return <div>Loading</div>
    // }
    const systemProcess = async () => {   //intricate function i will come back to ,

        // if (systemInitiated) {

        let newDate = latestInfo.PayNextDate;

        let converted = new Date(newDate);

        converted.setMonth(converted.getMonth() + 1, 1);
        let formatedDate = converted.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });


        const systemRecord = await fetch('https://backendjaes.passiontrials.co.za/payment_tb', {
            method: 'POST',
            headers: { 'Content-Type': "application/json" },
            body: JSON.stringify({
                UserId: latestInfo.UserId,
                ApartmentId: latestInfo.ApartmentId,
                PayDate: latestInfo.PayNextDate,
                PayNextDate: formatedDate,
                PayAmount: latestInfo.PayableRent,
                PayNote: 'System Initiated',
                PaymentStatus: 'OVERDUE',
                PaymentType: "Rent",
                PayAdvancePeriod: '15',
                PayGracePeriod: '3',
                PayMethod: "No Payment Attempt",
                Paid: true,
                PaidInAdvance: false,
                PaidInAdvanceDate: "N/A",
                IsGracePeriod: true,
                ChosenPlan: latestInfo.ChosenPlan,
                ServiceFee: latestInfo.ServiceFee,
                PayableRent: latestInfo.PayableRent,
                RentCost: latestInfo.RentCost

            })
        });

        // }

    };


    if (latestInfo) {
        theFirst = new Date(latestInfo.PayDate).toLocaleDateString('en-US', { day: 'numeric' });
        const currentD = new Date(dateNow).toLocaleDateString('en-US', { day: 'numeric' });
        showButtons = theFirst >= 1 && theFirst <= 31 || currentD >= 1 && currentD <= 31;
        //showButtons = true; for testing
        testCount = latestInfo?.PayNote == 'Checkout Month';
        tc = latestInfo?.PayNote == 'Monthly rent'; //has no html code to go with yet

        let convertedDate = new Date(latestInfo.PayNextDate);
        let currentDate = new Date();

        systemInitiated = convertedDate < currentDate;
        // if (daysOfStay < 0) {


        //     console.log('the condition is true');
        //     if (latestInfo?.PayNote == 'System Initiated') {
        //         systemProcess();
        //         console.log('the record of the system is recorded');
        //     }
        //     // 
        // }

    }

    useEffect(() => {
        if (latestInfo) {
            let convertedDate = new Date(latestInfo.PayNextDate);
            let currentDate = new Date();

            systemInitiated = convertedDate < currentDate;

            if (systemInitiated && daysOfStay < 0) {
                console.log('outside the function');
                if (latestInfo.PayNote == 'Monthly rent') {
                    systemProcess();
                    console.log('the function is called');
                }
            }

        }
    }, [latestInfo]);

    // if (daysOfStay ==)
    informedMessage = latestInfo?.PaymentStatus == 'OVERDUE';



    const toggleDropdown = (e) => {
        e.stopPropagation();
        setShowDropdown(!showDropdown);
    };
    const backToDash = () => {
        navigate('/UserDashboard');
    }

    const checkOut = () => {

      
            // Check if DaysPaidFor is less than 0
            
        
            // Proceed with the remaining balance check
            if (walletData?.DaysPaidFor > 0) {
                // Show the checkout modal if the remaining balance is 0 or less
                setShowCheckoutModal(true);
            } else {
                alert(`You cannot checkout without settling outstanding payment for the month of ${latestPaymentTrack?.MonthAndYearPaidFor}`);
                return;
            }
       
        


    }

    const confirmCheckout = () => {
        setShowCheckoutModal(false);
        setLoading(true); // Start the loader

        // Simulate delay before proceeding with checkout
        setTimeout(() => {
            setLoading(false); // Stop the loader
            navigate(`/checkOut?payid=${userId}`);
        }, 7000);
    };

    const handleModalClose = () => {
        setShowCheckoutModal(false);
    };
 // Calculate number of days in the current month
 const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
};

// Get the current date and determine the number of days in the current month
const currentDate = new Date();
const currentMonth = currentDate.getMonth(); // 0 = January, 11 = December
const currentYear = currentDate.getFullYear();
const daysInMonth = getDaysInMonth(currentYear, currentMonth); // Get the number of days in the current month

// Calculate daily pay based on apartment rent
const rentAmount = apartment?.ApartmentPrice || 0;
const dailyPay = rentAmount / daysInMonth; // Calculate daily pay
const handleModalSubmit = (amount) => {

    // Set the entered amount and close the modal
    setEnteredAmount(amount);
    setShowModal(false);  // Close the modal

    // Navigate to the recurringPayment page with the given state
    navigate('/recurringPayment', { 
        state: { amount, apartment, nextPayDate, latestInfo, latestPaymentTrack, dailyPay } 
    });
};




   const goToPayment = () => {
    const amountToPay = apartment?.ApartmentPrice; // Fetch the apartment price

    if (!amountToPay) {
        alert('Apartment price is not available.');
        return;
    }

    setShowModal(true); // Open the modal

    // Navigate to the recurringPayment page directly with the fixed apartment price
    navigate('/recurringPayment', {
        state: { amount: amountToPay, apartment, nextPayDate, latestInfo, latestPaymentTrack, dailyPay }
    });
};


    if (!apartment) {
        return (
            <div style={{ marginTop: '109px' }}>
                <h2>You have not booked an apartment yet.</h2>
                <Link to="/">Click here to book an apartment</Link>
            </div>


        );
    }

    if (loading) {
        return <Loader />; // Show only the loader while loading is true
    }
    const goToTransport = () => {
        if (apartment && apartment.BuildingName) {
            navigate(`/transport`, { state: { buildingName: apartment.BuildingName, userId: userId } });
        }
    };

    const goToServicebox = () => {
        if (apartment && apartment.BuildingName) {
            navigate(`/ServiceBox`, { 
                state: {  
                    buildingName: apartment.BuildingName, 
                    userId: userId, 
                    name: apartment.Name, 
                    surname: apartment.Surname 
                } 
            });
        }
    };
    

    return (
        <div >

            <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', padding: '20px', }}>

            <ImageUploadModal
    isVisible={showimageModal} // This will control whether the modal is shown
    onClose={() => setShowimageModal(false)}
    onUpload={handleImageUpload}
/>

                <PaymentModal
                    isVisible={showModal}
                    onClose={() => setShowModal(false)}
                    onSubmit={handleModalSubmit}
                />
                <CheckoutModal
                    isVisible={showCheckoutModal}
                    onClose={handleModalClose}
                    onConfirm={confirmCheckout}
                />

            </div>
            
            <Routes>
                <Route path="/bookings" element={<BookingList bookings={bookings} />} />
                <Route path="/payments" element={<PaymentList payments={payments} />} />
               
               
            </Routes>
            <Outlet />
            <body class="dashboard-page">  
                
                <h1 class="welcome-message">Hello, <span style={{color:'red'}}>{apartment.Name} {apartment.Surname}</span> </h1>
                <Link to="/Event_Ticket_Student">
  <button class="button-primary">Buy Event Ticket</button>
</Link>
  <div class="dashboard">
   
    <div class="card first-card">
      <img style={{ zIndex: '0' }} src="https://backendjaes.passiontrials.co.za/src/Building_images/IMG_20200725_132724.jpg" alt="Account Image" class="card-bg-img" />
      
      {walletData && (
        <h2 style={{ color: 'white' }}>
          Next payment is in: {walletData.DaysPaidFor}
        </h2>
      )}
      <h2 style={{ color: 'white' }}>  
        {systemInitiated ? "it's overdue" : ""}
      </h2>
      <small style={{ color: 'white' }}>{apartment.BuildingName} {apartment.ApartmentType}</small>
      <div class="card-buttons" style={{ display: '' }}>
        <button class="button-primary" onClick={goToPayment}>Renew</button>
        <button class="button-secondary" onClick={checkOut}>Checkout</button>
      </div>
    </div>
    
    <div class="card" onClick={goToTransport}>
      <h2>Transport</h2>
    
      <small>
        
        
      {Array.isArray(bookedTransports) && bookedTransports.length > 0 ? (
    <div>
      
      <table style={{ width: '75%', borderCollapse: '', borderRadius: '5px', overflow: 'hidden' }}>
        {/* <thead>
          <tr style={{ backgroundColor: '#f8f8f8', textAlign: 'left' }}>
            <th style={{ padding: '10px' }}>Date</th>
            <th style={{ padding: '10px' }}>Time</th>
            <th style={{ padding: '10px' }}>Direction</th>
            <th style={{ padding: '10px' }}>Institution</th>
            <th style={{ padding: '10px' }}>Building</th>
          </tr>
        </thead> */}
        <tbody style={{ width: '75%', borderCollapse: '', borderRadius: '5px', overflow: 'hidden' }}>
          {bookedTransports.slice(0, 2).map((booking, index) => (
            <tr key={index} style={{ borderBottom: '1px solid #000', textAlign: 'left', overflow: 'hidden'  }}>
              <td style={{ padding: '10px', color:"red" }}>{formatDate(booking.transport_date)}</td>
              <td style={{ padding: '10px', color:"red" }}>{booking.transport_time}</td>
              <td style={{ padding: '10px', color:"red" }}>{booking.direction}</td>
              {/* <td style={{ padding: '10px' }}>{booking.institution}</td> */}
              {/* <td style={{ padding: '10px' }}>{booking.building_name}</td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <p>No transports booked yet.</p>
  )}
        
        Book your ride to school</small>
    </div>
    
    <Link to="/SingleUserPayment">
      <div class="card">
        <h2>Payment History</h2>
        
        <small>
            {/* Use Reference: 076BWN-R01
        <br/>
Pay into the following account:<br/>
JAES Properties<br/>
FNB<br/>
Savings Account<br/>
Acc#: 62793916192
<hr/>
            <br/> */}
            Your invoices are here</small>
      </div>
    </Link>
    
    <Link to="/profile">
      <div class="card">
        <h2>Profile</h2>
        <small>Update your personal information</small>
      </div>
    </Link>
    
    <div class="card" onClick={goToServicebox}>
      <h2>Service Box</h2>
      <small>Drop us your complaints</small>
    </div>
  </div>
</body>



            {/* <div style={{ padding: '20px', backgroundImage: `url(${apartment.ApartmentRoomImage})`, backgroundSize: 'cover' }}>

                <div style={{ display: 'flex', justContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <h2></h2>

                        <p>
                        </p>

                        {testCount && (
                            <>
                                <p style={{ color: 'Red' }}>Final Month Of Stay</p>
                            </>
                        )}
                        {informedMessage && (
                            <>
                                <p style={{ color: 'Yellow' }}> This months stay is on credit,and will be added to your next payment.  </p>
                            </>
                        )}
                    </div>
                    <div>
                        {showButtons && (
                            <>
                                <button onClick={goToPayment} >Renew</button>
                            </>
                        )}
                        <button onClick={checkOut}>Checkout</button>

                    </div>
                    <p>
                        {systemInitiated ?
                            "its overdue" : "there is nothing here"
                        }
                    </p>
                </div>
            </div> */}
        </div >
    );
}

function BookingList({ bookings }) {
    return (
        <div>
            <h2>My Bookings</h2>
            {bookings.map(booking => (
                <div key={booking.id}>
                    <p>{booking.location} - {booking.date}</p>
                </div>
            ))}
        </div>
    );
}

function PaymentList({ payments }) {
    return (
        <div>
            <h2>My Payments</h2>
            {payments.map(payment => (
                <div key={payment.id}>
                    <p>Amount: {payment.amount} - Status: {payment.status} - Date: {payment.date}</p>
                </div>
            ))}
        </div>
    );
}


export default UserDashboard;
