import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Customers.css'; // Import the CSS for card styling
import { useAuth } from './AuthProvider';

function LandLord() {
    const [cust, setCust] = useState([]);
    const [tenantDetails, setTenantDetails] = useState([]); // Store tenant details with building information
    const [buildingFilter, setBuildingFilter] = useState('');
    const [roomTypeFilter, setRoomTypeFilter] = useState('');
    const { userId } = useAuth();
    const [areaFilter, setAreaFilter] = useState('');
    const [searchTerm, setSearchTerm] = useState(''); // State for search input
    const [sortOrder, setSortOrder] = useState('asc'); // Sorting order
    const [showTenantOnly, setShowTenantOnly] = useState(false); // Show tenants toggle
    const [buildings, setBuildings] = useState([]); // Stores buildings for the filter
    const [areas, setAreas] = useState([]); // Stores areas for the filter
    const [roomTypes, setRoomTypes] = useState([]); // Stores room types for the filter
    const [error, setError] = useState(null);
    const [attachedBuilding, setAttachedBuilding] = useState([]);
    const [displayUsers, setDisplayUsers] = useState([]);

    useEffect(() => {
        fetch('https://backendjaes.passiontrials.co.za/admin_tb')
            .then(res => res.json())
            .then(data => setAttachedBuilding(data))
            .catch(err => { console.log('An error occured:', err) });
        // Fetch customer data
        fetch('https://backendjaes.passiontrials.co.za/user_tb', {
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {
                if (data) {
                    setCust(data);
                } else {
                    console.log('No users found');
                }
            })
            .catch(err => {
                console.log('There was an error while fetching users:', err);
                setError('There was an error with fetching users');
            });

        // Fetch detailed tenant data from all_user_full_details
        // Fetch areas for area filter
        fetch('https://backendjaes.passiontrials.co.za/areas')
            .then(res => res.json())
            .then(data => setAreas(data))
            .catch(err => console.error('Error fetching areas:', err));
        // Fetch room types for room type filter
        fetch('https://backendjaes.passiontrials.co.za/room_types')
            .then(res => res.json())
            .then(data => setRoomTypes(data))
            .catch(err => console.error('Error fetching room types:', err));
    }, []);

    useEffect(() => {
        if (attachedBuilding) {
            fetch('https://backendjaes.passiontrials.co.za/all_user_full_details')
                .then(res => res.json())
                .then(data => {
                    const filter = data.filter((tenant) => tenant.CreatedBy == attachedBuilding.AdminEmail);
                    console.log('this is the tenant info', filter);
                    setTenantDetails(filter); // Store detailed information for all tenants
                    // Extract unique buildings from the data
                    const actualUsers = cust.filter((user) => { return !data.some(tenant => tenant.UserId == user.UserId); });
                    const allUsers = [...actualUsers, ...filter];
                    const allUniqueUsers = Array.from(new Set(allUsers.map(user => user.UserId))).map(id => allUsers.find(user => user.UserId === id));
                    setDisplayUsers(filter);
                    // console.log('the final array', displayUsers)
                    //  console.log('the tenants', filter)
                    // console.log('these are no associated users', actualUsers);
                    const uniqueBuildings = [...new Set(data.map(user => user.BuildingName))];
                    //const theBuildings = Array.from(new Set(filter.map(t => t.BuildingName)));
                    setBuildings(uniqueBuildings);
                    //console.log("this are the buildings in the list", theBuildings);
                })
                .catch(err => console.error('Error fetching all user details:', err));
        }
    }, [attachedBuilding, cust])

    useEffect(() => {
        if (userId && attachedBuilding.length > 0) {
            const filtered = attachedBuilding.filter((admin) => admin.AdminId == userId);
            setAttachedBuilding(filtered[0]);
            console.log('this is the admin info', filtered);
        }
    }, [userId, attachedBuilding]);
    // Function to get tenant details by UserId
    const getTenantDetails = (userId) => {
        return tenantDetails.find(tenant => tenant.UserId === userId);
    };

    // Filter customers based on search, tenant status, building, room type, and area
    const filteredCustomers = displayUsers
        .filter(cus =>
            `${cus.Name} ${cus.Surname} ${cus.IdNumber} ${cus.Email} ${cus.PhoneNumber}`
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
        )
        .filter(cus => !showTenantOnly || getTenantDetails(cus.UserId)) // Show only tenants if the toggle is on
        .filter(cus => !buildingFilter || (getTenantDetails(cus.UserId)?.BuildingName === buildingFilter)) // Filter by building
        .filter(cus => !roomTypeFilter || getTenantDetails(cus.UserId)?.LivingArrangement === roomTypeFilter) // Filter by room type
        .filter(cus => !areaFilter || getTenantDetails(cus.UserId)?.BuildingLocation === areaFilter) // Filter by area
        .sort((a, b) => {
            if (sortOrder === 'asc') {
                return a.Name.localeCompare(b.Name);
            } else {
                return b.Name.localeCompare(a.Name);
            }
        });

    return (
        <div className="customers-container">
            <h2>All Registered Users</h2>

            {/* Search bar */}
            <input
                type="text"
                placeholder="Search by name..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                className="search-bar"
            />

            {/* Filter buttons */}
            <div className="filters-container">
                {/* Tenant Filter */}
                <button
                    className={`filter-btn ${showTenantOnly ? 'active' : ''}`}
                    onClick={() => setShowTenantOnly(!showTenantOnly)}
                >
                    {showTenantOnly ? 'Show All' : 'Show Tenants'}
                </button>

                {/* Building Filter */}
                <select
                    value={buildingFilter}
                    onChange={(e) => setBuildingFilter(e.target.value)}
                    className="filter-dropdown"
                >
                    <option value="">All Buildings</option>
                    {buildings.map(building => (
                        <option key={building} value={building}>
                            {building}
                        </option>
                    ))}
                </select>

                {/* Area Filter */}
                <select
                    value={areaFilter}
                    onChange={(e) => setAreaFilter(e.target.value)}
                    className="filter-dropdown"
                >
                    <option value="">All Areas</option>
                    {areas.map(area => (
                        <option key={area.AreaId} value={area.AreaName}>
                            {area.AreaName}
                        </option>
                    ))}
                </select>

                {/* Room Type Filter */}
                <select
                    value={roomTypeFilter}
                    onChange={(e) => setRoomTypeFilter(e.target.value)}
                    className="filter-dropdown"
                >
                    <option value="">All Room Types</option>
                    {roomTypes.map(type => (
                        <option key={type.RoomTypeId} value={type.RoomType}>
                            {type.RoomType}
                        </option>
                    ))}
                </select>

                {/* Sorting Button */}
                <button
                    className="sort-btn"
                    onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
                >
                    Sort By Name: {sortOrder === 'asc' ? 'Ascending' : 'Descending'}
                </button>
            </div>

            {/* Customer Cards */}
            <div className="customer-cards">
                {filteredCustomers.length > 0 ? (
                    filteredCustomers.map(cus => (
                        <div key={cus.UserId} className="customer-card">
                            <h3>ID #{cus.IdNumber}</h3>
                            <p>
                                Customer: <span>{cus.Name} {cus.Surname}</span>
                            </p>
                            <p>User ID: <span>{cus.UserId}</span></p>
                            <p>Email: <span>{cus.Email}</span></p>
                            <p>Contact No.: <span>{cus.PhoneNumber}</span></p>
                            <p>Gender: <span>{cus.Gender}</span></p>
                            {getTenantDetails(cus.UserId) && (
                                <p>Building: <span>{getTenantDetails(cus.UserId).BuildingName}</span></p>
                            )}
                            <Link to={`/UserAccess/${cus.UserId}`} className="more-link">
                                More...
                            </Link>
                        </div>
                    ))
                ) : (
                    <p>No customers found.</p>
                )}
            </div>

            {error && <div className="error">{error}</div>}
        </div>
    );
}

export default LandLord;
